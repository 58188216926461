
import { Vue, Component, Ref } from "vue-property-decorator";
import { ListDataSource } from "@/data/List/ListDataSource";
import DataEditorDialog from "@/components/helpers/data/editor-dialog.vue";
import {mdiDownload, mdiPlus} from "@mdi/js";
import { ObjectDataSource } from "@/data/Object/ObjecDatatSource";
import { Filter } from "@/cms-services/filter";
import axios from "axios";
@Component({
  components: { DataEditorDialog },
})
export default class EventList extends Vue {
  @Ref("editor") editor!: DataEditorDialog;
  icons: any = {
    download:mdiDownload,
    plus: mdiPlus,
  };

  headers: any = [
    {
      text: "ID",
      value: "id",
      sortable: true,
    },
    {
      text: "Дата создания",
      value: "createDate",
      sortable: true,
    },
    {
      text: "ФИО",
      value: "fullName",
      sortable: true,
    },
    {
      text: "Статус",
      value: "instanceStateId",
      sortable: false,
    },
  ];

  userProfileDataSource: ListDataSource = new ListDataSource({
    className: "userProfile",
    config: {
      pageIndex: 1,
      pageSize: 3000,
    },
  });

  fields: any = [
    {
      editor: "auto-complete-select",
      attrs: {
        type: "text",
        outlined: true,
        label: "Выберите пользователя",
        noDataText: "Данные не найдены",
      },
      name: "userProfileId",
      config: {
        getItems: "getUserProfiles",
        labelKeyName: "fullName",
        valueKeyName: "id",
        type: "select",
      },
    },
  ];

  eventObjectDataSource = new ObjectDataSource({
    id: +this.$route.params.eventId,
    config: {},
    className: "event",
  });

  actions: any = {
    getUserProfiles: () => this.userProfileDataSource.items,
  };

  model: any = {
    eventId: +this.$route.params.eventId,
    userProfileId: null,
  };

  dataSource: any = new ListDataSource({
    config: {
      pageIndex: 1,
      pageSize: 15,
      filter: [
        new Filter("eventId", +this.$route.params.eventId),
      ].toFilterString(),
    },
    className: "EventRegistration",
  });
  loaded: boolean = false;
  $message: any;

  get breadcrumbs() {
    return [
      {
        text: "Главная",
        to: "/",
      },
      {
        text: "События",
        to: "/manage/events/",
        exact: true,
      },
      {
        text: this.eventObjectDataSource.model?.caption,
        to: `/manage/events/${this.$route.params.eventId}`,
        exact: true,
      },
      {
        text: "Регистрация",
        disabled: true,
      },
    ];
  }

  async created() {
    await this.eventObjectDataSource.get();
    await this.userProfileDataSource.get();
    this.loaded = true;
  }

  public async downloadExcel() {
    try {
      const { data } = await axios.get(`/manage/eventregistration/event/${+this.$route.params.eventId}/download/xlsx`, {
        responseType: "blob",
      });
      const url = window.URL.createObjectURL(data);
      const link = document.createElement("a");
      link.download = "Список пользователей.xlsx";
      link.href = url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      throw error;
    }
  }

  async add() {
    const model: any = await this.editor.update(this.model);
    if (!model) return;

    await this.dataSource.add(model);
    this.$message("Успешно добавлено");
  }
}
